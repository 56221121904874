<template>
  <div class="notFound">
    <!-- Aquí va tu estructura HTML -->
    <h1>{{ title }}</h1>
  </div>
</template>

<script>
export default {
  name: 'NombreDelComponente',  // Nombre del componente
  data() {
    return {
      title: '404 not found',
    };
  },
  methods: {
    // Aquí puedes definir métodos
  },
  computed: {
    // Computed properties
  },
  mounted() {
    // Código que se ejecuta cuando el componente se monta
  },
};
</script>

<style scoped>
.notFound{
  top: 100px;
  position: absolute;
  padding: 40px;
}
/* Aquí puedes agregar el estilo específico del componente */
h1 {
  color: #42b983;
}
</style>