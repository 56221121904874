<template>
  <div class="receta">
        <h2>Suspiros de monja</h2>
        <div class="imagenReceta2">
            
        </div>
        
        <h3>Ingredientes:</h3>
        <ul>
            <li>500 g de azúcar</li>
            <li>500 cc de agua</li>
            <li>8 yemas</li>
            <li>2 huevos</li>
            <li>1 cucharada de maicena</li>
            <li>1 cucharada de manteca</li>
            <li>90 g de harina</li>
            <li>50 g de almendra molida</li>
        </ul>
        <h3>Elaboración:</h3>
        <p class="elaboracion">1. Hacer un almíbar con el azúcar y el agua. Dejar hervir hasta que espese ligeramente.<br>
        2. Mezclar las yemas con la maicena y añadir al almíbar, removiendo constantemente hasta que espese en una crema.<br>
        3. Calentar agua con manteca y agregar la harina removiendo hasta que la masa se desprenda de las paredes del cazo.<br>
        4. Incorporar los huevos uno por uno y batir hasta obtener una masa homogénea.<br>
        5. Formar pequeñas bolitas de masa y freírlas en abundante aceite caliente hasta que estén doradas.<br>
        6. Escurrir, abrir y rellenar con nata. Servir acompañadas de almendra molida espolvoreada.</p>
    </div>
</template>

<script>
  export default {
    name: 'NombreDelComponente',  // Nombre del componente
    data() {
      return {
        title: '404 not found',
      };
    },
    methods: {
      // Aquí puedes definir métodos
    },
    computed: {
      // Computed properties
    },
    mounted() {
      // Código que se ejecuta cuando el componente se monta
    },
  };
</script>

<style scoped>
  
</style>