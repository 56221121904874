<template>
    <div class="vista">
      <div class="contRetiro">
        <img src="img/retiro(2).jpeg">  
        <img src="img/retiro(1).jpeg">
      </div>
      
    </div>
</template>

<script>
export default {
  data() {
    return {
      email: '',
      password: '',
      password2:'',
      error: '',
      nombre:''
    };
  },
  methods: {
    
    
  }
};
</script>

<style scoped>
.vista{
  padding-top: 66px;
  width: 100%;
  height: 100vh;
  display: flex;
  text-align: center;
  background-color: darkgray;
}
img{
  margin: auto;
  flex: 1;
  min-width: 250px;
  max-width: 500px;
}
.contRetiro{
  height: 100%;
  width: 100%;
  padding: 41px;
  gap: 20px;
  display: flex;
  flex-wrap: wrap;
  overflow: scroll;
}

::-webkit-scrollbar {
    display: none;
}

/* Ocultar la barra de desplazamiento en otros navegadores */
body, html {
    overflow: -moz-scrollbars-none; /* Para Firefox */
    -ms-overflow-style: none; /* Para IE y Edge */
}
</style>