<template>
  <div class="receta">
        <h2>Pestiños</h2>
        <div cass="imagenReceta8">
            
        </div>
        <h3>Ingredientes:</h3>
        <ul>
            <li>400 g de harina de trigo</li>
            <li>1/2 vaso de vino de Jerez</li>
            <li>1 cucharadita de matalahúga</li>
            <li>1/2 cucharadita de ajonjolí</li>
            <li>1 corteza de limón</li>
            <li>200 g de miel</li>
            <li>1/2 l de aceite de oliva</li>
            <li>Azúcar</li>
            <li>Sal al gusto</li>
        </ul>
        <h3>Elaboración:</h3>
        <p class="elaboracion">1. Calentar medio vaso de aceite en una sartén. Freír la corteza de limón y retirar. Añadir la matalahúga y el ajonjolí al aceite caliente, colar y reservar.<br>
        2. En un bol, hacer un volcán con la harina. Añadir sal, el vino de Jerez y el aceite colado. Amasar hasta obtener una masa homogénea.<br>
        3. Dejar reposar la masa durante una hora. Mientras, preparar un jarabe con miel y agua, y cocer a fuego lento durante 10 minutos.<br>
        4. Extender la masa con un rodillo en una lámina fina. Cortar cuadrados y unir las puntas para formar los pestiños.<br>
        5. Freír los pestiños en aceite caliente hasta dorar. Sumergirlos en la miel y escurrir. Espolvorear con azúcar antes de servir.</p>
    </div>
</template>

<script>
  export default {
    name: 'NombreDelComponente',  // Nombre del componente
    data() {
      return {
        title: '404 not found',
      };
    },
    methods: {
      // Aquí puedes definir métodos
    },
    computed: {
      // Computed properties
    },
    mounted() {
      // Código que se ejecuta cuando el componente se monta
    },
  };
</script>

<style scoped>
  
</style>