import { createRouter, createWebHistory } from 'vue-router';


import Products from './components/ProductsPage.vue';
import VerifyEmail from './components/VerifyEmail.vue';
import Home from './components/HomePage.vue';

import Register from './components/RegisterPage.vue';
import Login from './components/LoginPage.vue';
import Mantenimiento from './components/MantenimientoPage.vue';
import NotFoundPage from './components/NotFoundPage.vue';
import Cocina from './components/CocinaPage.vue';
import Oratorio from './components/OratorioPage.vue';
import Biblioteca from './components/BibliotecaPage.vue';
import Retiro from './components/RetiroPage.vue';
import Relato from './components/RelatoPage.vue';
import Libro1 from './components/libros/Libro1Page.vue';
import Libro2 from './components/libros/Libro2Page.vue';
import Libro5 from './components/libros/Libro5Page.vue';
import Libro7 from './components/libros/Libro7Page.vue';
import Libro8 from './components/libros/Libro8Page.vue';
import Libro1Comprar from './components/libros/EncargarLibro1Page.vue';
import Libro2Comprar from './components/libros/EncargarLibro2Page.vue';
import Libro5Comprar from './components/libros/EncargarLibro5Page.vue';
import Libro7Comprar from './components/libros/EncargarLibro7Page.vue';
import Libro8Comprar from './components/libros/EncargarLibro8Page.vue';
import Receta1 from './components/recetas/Receta1Page.vue';
import Receta2 from './components/recetas/Receta2Page.vue';
import Receta3 from './components/recetas/Receta3Page.vue';
import Receta4 from './components/recetas/Receta4Page.vue';
import Receta5 from './components/recetas/Receta5Page.vue';
import Receta6 from './components/recetas/Receta6Page.vue';
import Receta7 from './components/recetas/Receta7Page.vue';
import Receta8 from './components/recetas/Receta8Page.vue';
import Receta9 from './components/recetas/Receta9Page.vue';

const routes = [
  { path: '/', component: Home },
  { path: '/login', component: Login },
  { path: '/products', component: Products },
  { path: '/verify-email', component: VerifyEmail },
  { path: '/:pathMatch(.*)*', component: NotFoundPage },
  { path: '/register', component: Register },
  { path: '/mantenimiento', component: Mantenimiento },
  { path: '/cocina', component: Cocina },
  { path: '/oratorio', component: Oratorio },
  { path: '/biblioteca', component: Biblioteca },
  { path: '/retiro', component: Retiro },
  { path: '/libro-6', component: Relato },
  { path: '/libro-5', component: Libro5 },
  { path: '/libro-4', component: Libro1 },
  { path: '/libro-3', component: Libro1 },
  { path: '/libro-2', component: Libro2 },
  { path: '/libro-1', component: Libro1 },
  { path: '/libro-1/encargar', component: Libro1Comprar },
  { path: '/libro-2/encargar', component: Libro2Comprar },
  { path: '/libro-5/encargar', component: Libro5Comprar },
  { path: '/libro-7', component: Libro7 },
  { path: '/libro-7/encargar', component: Libro7Comprar },
  { path: '/libro-8', component: Libro8 },
  { path: '/libro-8/encargar', component: Libro8Comprar },
  { path: '/receta-1', component: Receta1 },
  { path: '/receta-2', component: Receta2 },
  { path: '/receta-3', component: Receta3 },
  { path: '/receta-4', component: Receta4 },
  { path: '/receta-5', component: Receta5 },
  { path: '/receta-6', component: Receta6 },
  { path: '/receta-7', component: Receta7 },
  { path: '/receta-8', component: Receta8 },
  { path: '/receta-9', component: Receta9 },
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

export default router;
