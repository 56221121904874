<template>
  <div class="imglog">
    <div class="opacity">
      
    </div>
  </div>
    <div class="contlog">
      
        <h2>Iniciar Sesión</h2>
        <h5>Email</h5>
        <input v-model="email" type="email" id="email" name="" required>
        <h5>Contraseña</h5>
        <input v-model="contraseña" type="password" id="contraseña" name="" required>
        <a href="">Olvide mi contraseña</a>
        <button class="btn" id="btnIngresar">Ingresar</button>
     
      
    </div>
</template>

<script>
  export default {
    data() {
      return {
        email: '',
        contraseña: '',
        message: ''
      };
    },
    methods: {
      
    }
  };
</script>

<style scoped>
.contlog{
  padding: 68px;
  color: #000000;
  display: grid;
}
#btnIngresar{
  width: 201px;
  height: 40px;
  background: #2468A8;
  border-radius: 25px;
  font-family: 'SF Pro Text';
  font-style: normal;
  font-weight: 300;
  font-size: 17px;
  line-height: 20px;
  color: #F5F5F5;
}
h5{
  font-size: 14px;
}
h2{
  font-size: 32px;
}
.imglog{
  overflow: hidden;
  display: flex;
  background-size: cover;
  background-image: url("../../public/img/e001ab13e335083ea8e6a38ecb7dc72f.jpg");
  height: 100vh;
  width: calc(100vw - 543px + 29px);
}

@media (max-width: 768px) {
  .contlog{
  padding: 20px;
    color: #000000;
    display: flex;
    gap: 20px;
    flex-direction: column;
    width: 100%;
    height: calc(60vh - 66px);
    margin-top: -20px;
    z-index: 9;
    position: sticky;
    border-radius: 20px 20px 0px 0px;
    
  }
  .contlog input{
    width: 100%;
  }
  .imglog{
    height: calc(40vh + 20px + 66px);
    width: 100%;
    background-position-x: center;
  }

}
</style>