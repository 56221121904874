<template>
  <div class="vista">
    <div class="filtroContainer">
      <h3>Filtrar por</h3>
      <h4>Categoria</h4>
      <h5>
        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M13.9912 22.7422C18.9746 22.7422 23.0879 18.6289 23.0879 13.6543C23.0879 8.67969 18.9658 4.56641 13.9824 4.56641C9.00781 4.56641 4.90332 8.67969 4.90332 13.6543C4.90332 18.6289 9.0166 22.7422 13.9912 22.7422ZM13.9912 20.9316C9.95703 20.9316 6.73145 17.6885 6.73145 13.6543C6.73145 9.62012 9.95703 6.38574 13.9824 6.38574C18.0166 6.38574 21.2598 9.62012 21.2686 13.6543C21.2773 17.6885 18.0254 20.9316 13.9912 20.9316Z" fill="#1C1C1E"/>
        </svg>
        Espiritualidad
      </h5>
      <h5>
        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M13.9912 22.7422C18.9746 22.7422 23.0879 18.6289 23.0879 13.6543C23.0879 8.67969 18.9658 4.56641 13.9824 4.56641C9.00781 4.56641 4.90332 8.67969 4.90332 13.6543C4.90332 18.6289 9.0166 22.7422 13.9912 22.7422ZM13.9912 20.9316C9.95703 20.9316 6.73145 17.6885 6.73145 13.6543C6.73145 9.62012 9.95703 6.38574 13.9824 6.38574C18.0166 6.38574 21.2598 9.62012 21.2686 13.6543C21.2773 17.6885 18.0254 20.9316 13.9912 20.9316Z" fill="#1C1C1E"/>
        </svg>
        Teología
      </h5>
      <h4>Precio</h4>
      <h5>
        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M13.9912 22.7422C18.9746 22.7422 23.0879 18.6289 23.0879 13.6543C23.0879 8.67969 18.9658 4.56641 13.9824 4.56641C9.00781 4.56641 4.90332 8.67969 4.90332 13.6543C4.90332 18.6289 9.0166 22.7422 13.9912 22.7422ZM13.9912 20.9316C9.95703 20.9316 6.73145 17.6885 6.73145 13.6543C6.73145 9.62012 9.95703 6.38574 13.9824 6.38574C18.0166 6.38574 21.2598 9.62012 21.2686 13.6543C21.2773 17.6885 18.0254 20.9316 13.9912 20.9316Z" fill="#1C1C1E"/>
        </svg>
        Gratis
      </h5>
      <h5>
        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M13.9912 22.7422C18.9746 22.7422 23.0879 18.6289 23.0879 13.6543C23.0879 8.67969 18.9658 4.56641 13.9824 4.56641C9.00781 4.56641 4.90332 8.67969 4.90332 13.6543C4.90332 18.6289 9.0166 22.7422 13.9912 22.7422ZM13.9912 20.9316C9.95703 20.9316 6.73145 17.6885 6.73145 13.6543C6.73145 9.62012 9.95703 6.38574 13.9824 6.38574C18.0166 6.38574 21.2598 9.62012 21.2686 13.6543C21.2773 17.6885 18.0254 20.9316 13.9912 20.9316Z" fill="#1C1C1E"/>
        </svg>
        Premium
      </h5>
    </div>
    <div class="novedadesContainer">
      <div class="bodyContainer">
        <div class="left" @click="scrollLeft">
          <svg width="13" height="22" viewBox="0 0 13 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 10.5801C0 10.9976 0.155064 11.3555 0.489049 11.6656L9.76904 20.7547C10.0315 21.0172 10.3654 21.1603 10.7591 21.1603C11.5463 21.1603 12.1785 20.54 12.1785 19.7409C12.1785 19.3472 12.0115 19.0013 11.7491 18.727L3.38756 10.5801L11.7491 2.43331C12.0115 2.15897 12.1785 1.80113 12.1785 1.41943C12.1785 0.620257 11.5463 0 10.7591 0C10.3654 0 10.0315 0.143136 9.76904 0.405552L0.489049 9.48277C0.155064 9.80483 0 10.1627 0 10.5801Z" fill="#1C1C1E"/>
          </svg>
        </div>
        <div class="center">
          <div class="topContainer">
            <h4>Novedades</h4>
          </div>
          <div class="libros" ref=scrollContainer>
            <div class="containerLibro" v-for="(libro,index) in libros" :key="index">
              <div class="boxLibro" @mouseenter="onMouseEnter(index)" @mouseleave="onMouseLeave">
                <div class="imgLibroContainer">
                    <img v-show="hover!=index" :src="libro.urlTapa">
                    <img v-show="hover==index" :src="libro.urlContratapa">
                </div>
                <h5>{{libro.nombre}}</h5>
                <h5 v-if="libro.id!=5">${{libro.precio}}</h5>
                <transition v-if="libro.stock" name="fade">
                  <div v-show="hover==index" class="buttonsContainer">
                    <router-link v-if="libro.id!=5" :to="'/libro-'+(libro.id+1)+'/encargar'">
                      <button v-if="libro.id!=5" class="btn">Comprar</button>
                    </router-link>

                    <router-link v-if="libro.id!=5" :to="'/libro-'+(libro.id+1)">
                      <button class="btn">Ver</button>
                    </router-link>

                    <router-link v-if="libro.id==5" to="/libro-6"><button  class="btn">Ver</button></router-link>
                  </div>
                </transition>
                <transition v-if="!libro.stock" name="fade">
                  <div v-show="hover==index" class="buttonsContainer">
                    <button v-if="libro.id!=5" class="sinStock">Sin Stock</button>
                    <router-link v-if="libro.id==5" to="/libro-6"><button  class="btn">Ver</button></router-link>
                  </div>
                </transition>
              </div>
            </div>
          </div>
          
        </div>
        
        <div class="right" @click="scrollRight">
          <svg width="13" height="23" viewBox="0 0 13 23" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.6697 11.5308C12.6578 11.1134 12.5027 10.7555 12.1807 10.4335L2.90067 1.35624C2.62633 1.09382 2.30427 0.950684 1.91064 0.950684C1.11147 0.950684 0.491211 1.57094 0.491211 2.37012C0.491211 2.75181 0.646275 3.10965 0.920619 3.384L9.27023 11.5308L0.920619 19.6777C0.646275 19.952 0.491211 20.2979 0.491211 20.6915C0.491211 21.4907 1.11147 22.111 1.91064 22.111C2.29234 22.111 2.62633 21.9678 2.90067 21.7054L12.1807 12.6163C12.5146 12.3062 12.6697 11.9483 12.6697 11.5308Z" fill="#1C1C1E"/>
            </svg>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NombreDelComponente',  // Nombre del componente
  data() {
    return {
      title: '404 not found',
      hover:-1,
      libros:[
      {
        nombre:'Laudes Monasterio San Macario',
        precio:'6.000,00',
        urlTapa:'/img/tapa(7).jpg',
        urlContratapa:'/img/tapa(7).jpg',
        stock:true,
        id:6
      },
      {
        nombre:'Vísperas Monasterio San Macario',
        precio:'6.000,00',
        urlTapa:'/img/tapa(8).jpg',
        urlContratapa:'/img/tapa(8).jpg',
        stock:true,
        id:7
      },
      {
        nombre:'Orar con Maria',
        precio:'10.000,00',
        urlTapa:'/img/tapa(1).jpg',
        urlContratapa:'/img/contratapa(1).jpg',
        stock:true,
        id:0
      },
      {
        nombre:'Orar con los Pajaros',
        precio:'10.000,00',
        urlTapa:'/img/tapa(2).jpg',
        urlContratapa:'/img/contratapa(2).jpg',
        stock:true,
        id:1
      },
      {
        nombre:'El canto de Dios',
        precio:'10.000,00',
        urlTapa:'/img/tapa(3).jpg',
        urlContratapa:'/img/contratapa(3).jpg',
        stock:false,
        id:2
      },
      {
        nombre:'Orar con la Noche',
        precio:'10.000,00',
        urlTapa:'/img/tapa(4).jpg',
        urlContratapa:'/img/contratapa(4).jpg',
        stock:false,
        id:3
      },
      {
        nombre:'Ellos oran por nosotros',
        precio:'10.000,00',
        urlTapa:'/img/tapa(5).jpg',
        urlContratapa:'/img/contratapa(5).jpg',
        stock:true,
        id:4
      },
      {
        nombre:'Relatos de un Peregrino Ruso',
        precio:'10.000,00',
        urlTapa:'/img/tapa(6).jpg',
        urlContratapa:'/img/tapa(6).jpg',
        stock:true,
        id:5
      }
      ]
    };
  },
  methods: {
    scrollLeft() {
      // Hace scroll 300px a la izquierda del contenedor
      this.$refs.scrollContainer.scrollBy({ left: -200, behavior: 'smooth' });
    },
    scrollRight() {
      // Hace scroll 300px a la derecha del contenedor
      this.$refs.scrollContainer.scrollBy({ left: 200, behavior: 'smooth' });
    },
    onMouseEnter(index){
      this.hover=index
    },
    onMouseLeave(){
      this.hover=-1
    },
    redirigir(index){
      this.$router.push('/libro'+index);
    }
  },
  computed: {
    // Computed properties
  },
  mounted() {
    // Código que se ejecuta cuando el componente se monta
  },
};
</script>

<style scoped>
.notFound{
  top: 100px;
  position: absolute;
  padding: 40px;
}
/* Aquí puedes agregar el estilo específico del componente */
h1 {
  color: #42b983;
}
h3{
  font-size: 20px;
}
h4{
  font-size: 18px;
}
h5{
  font-size: 16px;
}
.vista{
  color: black;
  padding-top: 66px;
  display: flex;
}
.filtroContainer{
  padding-left: 41px;
  width: 170px;
  display: grid;
  padding-top: 100px;
  display: none;
}
.filtroContainer h5{
  width: 150px;
  display: flex;
  align-items: center;
}
.novedadesContainer{
  width: 100%;
  overflow: scroll;
}
.bodyContainer{
  display: flex;
}
.left, .right{
  width: 70px;
  display: flex;
  text-align: center;
}
.left svg, .right svg{
  margin: auto;
}
.center{
  height: calc(100vh - 66px);
  width: 100%;
}
.bodyContainer, .center{
  overflow: hidden;
}
.libros{
  overflow: scroll;
}
::-webkit-scrollbar {
  display: none;
}
.boxLibro{
width: 166px;
height: 300px;
padding-top: 10px;
text-align: center;
display: grid;
background: #2468A8;
border-radius: 10px;
transition: height 0.3s ease-in-out;
}
.boxLibro:hover{
  height: 339px;

}
.boxLibro h5{
  font-size: 15px;
  color: #f5f5f5;
}
.containerLibro{
  margin-left: auto;
  margin-right: 41px;
}
.boxLibro img{
width: 147px;
height: 229px;
border-radius: 10px;
}
.imgLibroContainer{
  width: 147px;
height: 229px;
border-radius: 10px;
margin-left: 10px;
}
.libros{
  display: flex;
  padding-top: 30px;
}
.center{
  padding-top: 30px;
}
.btn{
margin: auto;
width: 67px;
height: 29px;
background: #F5F5F5;
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
border-radius: 15px;
padding: 0px;
font-size: 14px;
color: #2468A8;

}
a{
  margin: auto;
}
.sinStock{
    /* Frame 17 */

  box-sizing: border-box;

  /* Auto layout */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px 11px;
  gap: 10px;
  width: 146px;
  height: 29px;
  margin: auto;
  color: #f5f5f5;
  background: #2468A8;
  border: 1px solid #E2E2E2;
  border-radius: 10px;

}
.buttonsContainer{
  display: flex;
  width: 166px;
  margin-bottom: 10px;
}
.fade-enter-active{
  transition: opacity 0.5s ease; /* Duración y suavizado de la transición */
}
.fade-leave-active {
  transition: opacity 0.5s ease; /* Duración y suavizado de la transición */
}
.fade-enter, .fade-leave-to /* .fade-leave-active en versiones anteriores a 2.1.8 */ {
  opacity: 0; /* El elemento está inicialmente invisible o se desvanece */
}

@media (max-width: 768px) {
  .left, .right{
    display: none;
  }
  .novedadesContainer{
    padding: 10px;
  }
  .libros{
    height: 100%;
    padding-bottom: 30px;
    row-gap: 10px;

    flex-wrap: wrap;
    justify-content: space-between;
  }
  .containerLibro{
    margin: 0;
    flex: 1;
    display: flex;

        justify-content: center;
  }
}
</style>