<template>
  <div class="receta">
        <h2>Panecillos de Santa Clara</h2>
        <div class="imagenReceta4">
            
        </div>
        
        <h3>Ingredientes:</h3>
        <ul>
            <li>75 g de leche</li>
            <li>60 g de agua</li>
            <li>1/4 cucharadita de levadura</li>
            <li>30 g de aceite de oliva</li>
            <li>30 g de azúcar</li>
            <li>100 g de harina de trigo</li>
            <li>125 g de harina de fuerza</li>
        </ul>
        <h3>Elaboración:</h3>
        <p class="elaboracion">1. Calentar la leche, el agua y la levadura durante 3 minutos a 37ºC.<br>
        2. Añadir el aceite de oliva, azúcar, harinas y una pizca de sal. Amasar durante 3 minutos en velocidad de amasado o manualmente hasta obtener una masa elástica.<br>
        3. Dejar reposar la masa en un bol aceitado durante 1 hora o hasta que doble su volumen.<br>
        4. Dividir la masa en 8 porciones, formar bolitas, y hacer un pequeño corte en cruz en la parte superior de cada una.<br>
        5. Dejar levar las porciones nuevamente hasta que doblen su volumen, unos 60 minutos.<br>
        6. Hornear a 180°C durante 20-25 minutos. Dejar enfriar antes de servir.</p>
    </div>
</template>

<script>
  export default {
    name: 'NombreDelComponente',  // Nombre del componente
    data() {
      return {
        title: '404 not found',
      };
    },
    methods: {
      // Aquí puedes definir métodos
    },
    computed: {
      // Computed properties
    },
    mounted() {
      // Código que se ejecuta cuando el componente se monta
    },
  };
</script>

<style scoped>
  
</style>