<template>
  <div>
    <iframe id="#pdf" src="./pdf/Relatos-de-un-Peregrino-Ruso.pdf" width="100%"></iframe>
  </div>
</template>

<script>
export default {
  data() {
    return {
      email: '',
      password: '',
      password2:'',
      error: '',
      nombre:''
    };
  },
  methods: {
    
    
  }
};
</script>

<style scoped>
iframe{
  margin-top: 66px;
  height: calc(100vh - 66px);
}
</style>