<template>
  <div class="receta">
        <h2>Rosquitas de manteca</h2>
        <div class="imagenReceta5">
            
        </div>
        <h3>Ingredientes:</h3>
        <ul>
            <li>2 3/4 tazas de harina</li>
            <li>Pizca de sal</li>
            <li>3/4 de taza de azúcar molida</li>
            <li>3/4 de taza de manteca</li>
            <li>1 cucharadita de anís en grano</li>
            <li>1/4 de taza de agua fría</li>
        </ul>
        <h3>Elaboración:</h3>
        <p class="elaboracion">1. Cernir la harina, sal y azúcar en una superficie plana.<br>
        2. Hacer un hoyo en el centro de la mezcla y agregar la manteca, el anís y el agua fría. Amasar hasta unir bien todos los ingredientes.<br>
        3. Formar las rosquitas estirando dos porciones de masa y trenzándolas en forma de círculo.<br>
        4. Colocar las rosquitas en una bandeja de horno y hornear a 325°F (170°C) hasta que doren ligeramente. Dejar enfriar y guardar en un envase hermético.</p>
    </div>
</template>

<script>
  export default {
    name: 'NombreDelComponente',  // Nombre del componente
    data() {
      return {
        title: '404 not found',
      };
    },
    methods: {
      // Aquí puedes definir métodos
    },
    computed: {
      // Computed properties
    },
    mounted() {
      // Código que se ejecuta cuando el componente se monta
    },
  };
</script>

<style scoped>
  
</style>