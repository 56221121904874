<template>
  <div class="receta">
        <h2>Tortas de polvorón</h2>
        <div class="imagenReceta6">
            
        </div>
        <h3>Ingredientes:</h3>
        <ul>
            <li>375 g de ajonjolí</li>
            <li>2 tazas de aceite de oliva</li>
            <li>2 tazas de manteca blanca</li>
            <li>2 tazas de azúcar</li>
            <li>Ralladura de 4 limones</li>
            <li>2 sobres de levadura en polvo</li>
            <li>1 copa de aguardiente</li>
            <li>6 yemas de huevo</li>
            <li>Harina (la necesaria)</li>
            <li>Azúcar glass para espolvorear</li>
        </ul>
        <h3>Elaboración:</h3>
        <p class="elaboracion">1. Derretir la manteca y mezclar con el aceite. Añadir el ajonjolí, azúcar, ralladura de limón, levadura, aguardiente y las yemas de huevo.<br>
        2. Incorporar la harina poco a poco hasta obtener una masa consistente. Dejar reposar 10-15 minutos.<br>
        3. Precalentar el horno a 190°C. Cortar la masa en porciones del tamaño deseado y hornear durante 15 minutos.<br>
        4. Al sacar del horno, espolvorear las tortas con azúcar glass. Una vez frías, envolver en papeles de colores si se desea para su presentación.</p>
    </div>
</template>

<script>
  export default {
    name: 'NombreDelComponente',  // Nombre del componente
    data() {
      return {
        title: '404 not found',
      };
    },
    methods: {
      // Aquí puedes definir métodos
    },
    computed: {
      // Computed properties
    },
    mounted() {
      // Código que se ejecuta cuando el componente se monta
    },
  };
</script>

<style scoped>
  
</style>