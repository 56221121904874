<template>
  <div>
    <h2>Productos</h2>
    <div v-if="products.length === 0">No hay productos disponibles.</div>
    <div v-else>
      <div v-for="product in products" :key="product.id" class="product">
        <h3>{{ product.nombre }}</h3>
        <p>{{ product.descripcion }}</p>
        <p>Precio: ${{ product.precio }}</p>
        <img :src="product.imagen_url" alt="Imagen del producto" />
        <button @click="buyProduct(product.id)">Comprar</button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { ref, onMounted } from 'vue';

export default {
  setup() {
    const products = ref([]);

    onMounted(async () => {
      try {
        const response = await axios.get('macariobackend-production.up.railway.app/api/products/products');
        products.value = response.data;
      } catch (error) {
        console.error('Error al obtener productos:', error);
      }
    });

    const buyProduct = async (productId) => {
      const userId = 1; // Reemplaza con el ID del usuario actual

      try {
        await axios.post('http://localhost:5000/api/products/buy', { productId, userId });
        alert('Compra realizada con éxito');
      } catch (error) {
        console.error('Error al realizar la compra:', error);
      }
    };

    return { products, buyProduct };
  }
};
</script>

<style>
.product {
  border: 1px solid #ccc;
  padding: 10px;
  margin: 10px 0;
  text-align: center;
}

.product img {
  max-width: 100%;
  height: auto;
}
</style>
