<template>
  <div>
    <h2>Verificación de correo electrónico</h2>
    <p v-if="message">{{ message }}</p>
  </div>
</template>

<script>
import axios from 'axios';
import { onMounted, ref } from 'vue';

export default {
  setup() {
    const message = ref('');

    onMounted(async () => {
      const urlParams = new URLSearchParams(window.location.search);
      const token = urlParams.get('token');

      try {
        await axios.get(`macariobackend-production.up.railway.app/api/users/verify-email?token=${token}`);
        message.value = 'Correo electrónico verificado con éxito';
      } catch (error) {
        message.value = 'Error al verificar el correo electrónico';
      }
    });

    return { message };
  }
};
</script>
