<template>
  <div class="vista">
    <div class="imagesCont">
      <div class="left">
        <div v-for="(url,index) in imgsURL" :key="index" class="imgCont">
          <img :src="url" @click="cambiarImagen(index)">
        </div>
      </div>  
      <div class="right">
        <div class="imgCont">
          <img :src="imgSelected">
        </div>
      </div>
    </div>
    <div class="descripCont">
      <h2>{{nombre}}</h2>
      <h3>{{precio}}</h3>
      <div class="contTextButtons">
        <button class="btn descrbtn" @click="setText('descripcion')">Descripcion</button>
        <button class="btn especibtn" @click="setText('especificaciones')">Especificaciones</button>
      </div>
      <div class="text">
        <div v-if="text=='descripcion'">
          <h4 v-for="(desc,index) in descripcion" :key="index">{{desc}}</h4>  
        </div>
        
        <h4 v-if="text=='especificaciones'">
          Titulo: {{especificaciones.titulo}}
          <br>
          Autor: {{especificaciones.autor}}
          <br>
          Editorial: {{especificaciones.editorial}}
          <br>
          Dimensiones: {{especificaciones.dimensiones}}
          <br>
          Numero de paginas: {{especificaciones.paginas}}
        </h4>
      </div>
      <router-link to="/libro-5/encargar">
        <button class="btn encargarButtons">Encargar</button>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      nombre:"Ellos oran por nosotros",
      precio:"$10.000",
      descripcion:['Ellos oran por nosotros:','Orar por aquellos que se han ido es una práctica muy arraigada en nuestra Iglesia. Saber que ellos oran por nosotros nos llena de confianza y seguridad. Nos permite sentirnos protegidos, mirados y mimados por aquellos que alguna vez amamos en la tierra. Nos da la certeza de lo perenne del amor humano que llega a trascender a la vida misma gracias al encuentro con Cristo.','Pensar en la Iglesia como una comunidad orante en el cielo y en la tierra magnifica la visión de la esposa de Cristo. La oración es la forma de comunicación que nos permite seguir cultivando ese vínculo de unión y amor que pudimos gestar al compartir parte de nuestra vida terrenal con quienes ya gozan del cielo en la presencia de Dios.','Ambos, ellos y nosotros nos vemos beneficiados por las riquezas espirituales que podamos obtener trabajando para el establecimiento definitivo del reino de Dios.','Te invito hermano a que transformes esa relación de amor con tu ser querido, a través del encuentro con Cristo. Teniendo siempre presente, que nuestro Dios no es un Dios de muertos, sino de vivos.'],
      especificaciones:{
        titulo:"Ellos oran por nosotros",
        autor:"Ottero, Ariel",
        editorial:"Lliu Lliu",
        dimensiones:"21 x 14 Cm",
        paginas:"70"
      },
      imgsURL:["/img/tapa(5).jpg","/img/contratapa(5).jpg"],
      imgSelected:"",
      text:"descripcion"
    };
  },
  methods: {
    setText(text){
      this.text=text
    },
    cambiarImagen(index){
      this.imgSelected=this.imgsURL[index]
    }
  },
  mounted() {
    this.imgSelected=this.imgsURL[0]
  },
};
</script>

<style scoped>
.vista{
  height:100vh - 66px;
  width: 100%;
  padding: 41px;
  padding-top: 107px;
  color: black;
  display: flex;
  background-color: #f5f5f5;
}
.imagesCont{
  width: 742px;
  height: 100%;
  display: flex;
}
.left{
  height: 100%;
  margin-right: auto;
}
.left .imgCont{
  width: 136px;
  height: 136px;
  margin-bottom: 26px;
  margin-right: 26px;
  border-radius: 19px;
  overflow: hidden;
  text-align: center;
  background-color: white;
}
.left .imgCont img{
  height: 100%;
}
.right{
  width: 526px;
  height: 100%;
  margin-left: auto;
}
.right .imgCont{
  width: 526px;
  height: 100%;
  overflow: hidden;
  text-align: center;
  background-color: white;
  border-radius: 19px;
}
.right .imgCont img{
  height:100% ;
}
.descripCont{
  width: 425px;
  min-width: 425px;
  margin: auto;
  height: 100%;
  display: grid;
}
.text{
  height: 240px;
  overflow: scroll;
}
.contTextButtons{
}
.descrbtn{
  width: 124px;
  height: 38px;
  color: black;
  background: #D9D9D9;
  border-radius: 8.63426px 0px 0px 8.63426px;
  font-size: 14px;
  padding: 0;
}
.especibtn{
  width: 124px;
  height: 38px;
  background: #D9D9D9;
  border-radius: 0px 8.63px 8.63px 0px;
  font-size: 14px;
  padding: 0;
}
.encargarButtons{
  font-size: 22px;
  width: 100%;
  height: 54px;
  color: #f5f5f5;
  background: #2468A8;
  box-shadow: 0px 3.8px 3.8px rgba(0, 0, 0, 0.25);
  border-radius: 19px;

}
h2{
  font-size: 34px;
}
h3{
  font-size: 22px;
}
h4{
  font-size: 15px;
}
a{
  width: 100%;
}
@media (max-width: 768px) {
  .vista{
    gap: 10px;
    flex-direction: column;
  }
  .imagesCont, .right,.right .imgCont, img, h2, h3, h4, .contTextButtons, .descripCont{
    width: 100%;
  }
  .left{
    display: none;
  }
  .descripCont{
    min-width: 0;
    gap: 10px;
  }
}
</style>