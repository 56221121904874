<template>
  <div class="receta">
        <h2>Hojaldrinas de las monjas de Santa María del Socorro</h2>
        <div class="imagenReceta3">
            
        </div>
        
        <h3>Ingredientes:</h3>
        <ul>
            <li>250 g de manteca de cerdo</li>
            <li>200 g de mantequilla</li>
            <li>150 ml de vino blanco</li>
            <li>550-570 g de harina de repostería</li>
            <li>1 cucharadita de extracto de vainilla</li>
            <li>¼ cucharadita de sal</li>
            <li>Azúcar glas para espolvorear</li>
        </ul>
        <h3>Elaboración:</h3>
        <p class="elaboracion">1. Colocar en un bol grande la manteca de cerdo y la mantequilla a temperatura ambiente, ambas en punto de pomada. Batir hasta que estén bien mezcladas pero no derretidas.<br>
        2. Añadir el vino blanco y continuar batiendo hasta que esté todo más o menos integrado. No es necesario que el vino se mezcle completamente.<br>
        3. Tamizar la harina y añadir 550 g a la mezcla anterior. Remover bien hasta formar una masa homogénea. Si la masa está pegajosa, añadir más harina poco a poco hasta que ya no se pegue a los dedos.<br>
        4. Envolver la masa en papel film y dejar reposar por dos horas en un lugar fresco.<br>
        5. Estirar la masa con un rodillo hasta que tenga un grosor de un centímetro. Cortar tiras de 3-4 cm de ancho y luego en cuadrados.<br>
        6. Colocar las hojaldrinas en una bandeja para hornear y refrigerar mientras se precalienta el horno a 190°C.<br>
        7. Hornear durante 30 minutos. Dejar enfriar y espolvorear con azúcar glas.</p>
    </div>
</template>

<script>
  export default {
    name: 'NombreDelComponente',  // Nombre del componente
    data() {
      return {
        title: '404 not found',
      };
    },
    methods: {
      // Aquí puedes definir métodos
    },
    computed: {
      // Computed properties
    },
    mounted() {
      // Código que se ejecuta cuando el componente se monta
    },
  };
</script>

<style scoped>
  
</style>