<template>
  <div class="receta">
        <h2>Tocino de cielo</h2>
        <div class="imagenReceta1">
            
        </div>
        
        <h3>Ingredientes:</h3>
        <ul>
            <li>250 cc de agua</li>
            <li>250 g de azúcar</li>
            <li>8 yemas</li>
        </ul>
        <h3>Elaboración:</h3>
        <p class="elaboracion">1. Hacer un almíbar con el agua y el azúcar, dejándolo enfriar ligeramente.<br>
        2. Batir las yemas y añadir lentamente el almíbar como si se estuviera haciendo una mayonesa, cuidando que no se corte.<br>
        3. Reservar una parte del almíbar y ponerlo al fuego para espesarlo un poco más.<br>
        4. Bañar los moldes con este almíbar espeso antes de añadir la mezcla de yemas.<br>
        5. Cocer los moldes al baño maría a fuego lento, tapados con un paño que recoja el vapor.<br>
        6. Una vez fríos, desmoldar y servir con un poco del almíbar reservado por encima para darles brillo.</p>
    </div>
</template>

<script>
  export default {
    name: 'NombreDelComponente',  // Nombre del componente
    data() {
      return {
        title: '404 not found',
      };
    },
    methods: {
      // Aquí puedes definir métodos
    },
    computed: {
      // Computed properties
    },
    mounted() {
      // Código que se ejecuta cuando el componente se monta
    },
  };
</script>

<style scoped>
  
</style>