<template>
  <div class="vista">
    <div class="contimg imginicio">
      <div>
        <h2>San Macario</h2>
        <h5>Encuentra la paz interior y la huella divina en cada espacio de nuestro monasterio</h5>
        <button class="btn btninformacion">Más Información</button>
      </div>
    </div>
    <div class="imgpagos">
      <div class="opacity">
        <div class="top">
          <h2>Elegí tu plan de suscripción</h2> 
        </div>
        <div class="bottom">
          <div class="planbox planboxcenter">
            <div class="contplan">
              <div class="conth3">
                <div>
                  <h3>Gratis</h3>   
                </div>
              </div>
              <div>
                <ul>
                  <li>
                    <h4>Acceso a recetas gratuitas</h4>
                  </li>
                  <li>
                    <h4>Acceso a libros de texto gratuitos</h4>
                  </li>
                  <li>
                    <h4>Tiempo limite de oración</h4>
                  </li>
                </ul> 
              </div>
            </div>
            <div class="contbtn">
              <button class="btn">Suscribirse</button>  
            </div>  
          </div>
          <div class="planbox planboxcenter">
            <div class="contplan">
              <div class="conth3">
                <div>
                  <h3>$5.000</h3><h4>/Mes</h4>
                </div>
              </div>
              <div>
                <ul>
                  <li>
                    <h4>Acceso libre a recetas</h4>
                  </li>
                  <li>
                    <h4>Acceso libre a libros de texto</h4>
                  </li>
                  <li>
                    <h4>Tiempo personalizable de oración</h4>
                  </li>
                </ul> 
              </div>
            </div>
            <div class="contbtn">
              <button class="btn">Suscribirse</button>  
            </div>
          </div>

          <div class="planbox planboxcenter">
            <div class="contplan">
              <div class="conth3">
                <div>
                  <h3>$27.000</h3><h4 style="width: 75px">/6 meses</h4>
                </div>
              </div>
              <div>
                <ul>
                  <li>
                    <h4>Acceso libre a recetas</h4>
                  </li>
                  <li>
                    <h4>Acceso libre a libros de texto</h4>
                  </li>
                  <li>
                    <h4>Tiempo personalizable de oración</h4>
                  </li>
                  <li>
                    <h4>Descuento del 10% por mes</h4>
                  </li>
                </ul> 
              </div>
            </div>
            <div class="contbtn">
              <button class="btn">Suscribirse</button>  
            </div>
          </div>

          <div class="planbox planboxcenter">
            <div class="contplan">
              <div class="conth3">
                <div>
                  <h3>$48.000</h3><h4>/Año</h4>
                </div>
              </div>
              <div>
                <ul>
                  <li>
                    <h4>Acceso libre a recetas</h4>
                  </li>
                  <li>
                    <h4>Acceso libre a libros de texto</h4>
                  </li>
                  <li>
                    <h4>Tiempo personalizable de oración</h4>
                  </li>
                  <li>
                    <h4>Descuento del 20% por mes</h4>
                  </li>
                </ul> 
              </div>
            </div>
            <div class="contbtn">
              <button class="btn">Suscribirse</button>  
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      email: '',
      password: '',
      error: '',
      nombre:''
    };
  },
  methods: {
    
  }
};
</script>

<style scoped>
@media (max-width: 768px) {
  .imginicio{
    height: 100vh;
    background-position-x: 45%;
    margin-top: 66px;
    padding: 0px;
  }
  .imginicio div{
    margin: 0;
    padding: 10px;
    padding-top: 40px ;
    background: rgba(78, 77, 77, 0.62);
    width: 100%;
  }
  .imginicio button{
    display: none;

  }
  .top{
    height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .opacity{
    padding: 0;
  }
  .conth3 div{
    display: block;
  }
  .bottom{
    display: flex;
        margin-top: 0;
        flex-wrap: wrap;
        justify-content: center;
        height: calc(100vh - 150px - 66px);
        overflow: scroll;
        gap: 20px;

        align-content: flex-start;
  }
  .planbox{
            margin: 0;
        width: 45%;
        height: 320px;
        padding: 10px;
  }
  h3, h4{
    margin: 0;
  }
}
::-webkit-scrollbar {
    display: none;
}

/* Ocultar la barra de desplazamiento en otros navegadores */
body, html {
    overflow: -moz-scrollbars-none; /* Para Firefox */
    -ms-overflow-style: none; /* Para IE y Edge */
}
</style>
