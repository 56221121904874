<template>
  <div class="vista">
    <div class="imgOratorio">
      <div class="topcontainer">
        <div class="contButtons">
          <div>
            <button class="btn contButtonsButton" @click="startTimer(300)">5 Min</button>
          </div>
          <div>
            <button class="btn contButtonsButton" @click="startTimer(600)">10 Min</button>
          </div>
          <div>
            <button class="btn contButtonsButton" @click="startTimer(900)">15 Min</button>
          </div>
          <div>
            <button class="btn contButtonsButton" @click="personalizar()" :class="personalizadoClass">Personalizar</button>
            <div v-show="personalizarMenu" id="menuPersonalizar">
              <div>
                min
                <input type="number" v-model="minutosP" name="">
              </div>
              <div>
                seg
                <input type="number" v-model="segundosP" name="">  
              </div>
              
              <button class="btn contButtonsButton" @click="iniciarTiempo(minutosP,segundosP)">Comenzar</button>
            </div>
          </div>
        </div>
        <div class="espace">
          
        </div>
        <div class="contador">
          <h3>{{contador}}</h3>
        </div>
      </div>
      <div class="espace">
        
      </div>
      <div class="contAudio">
        <div class="menu">
          <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.40165 11.6338H24.5884C25.075 11.6338 25.4739 11.2349 25.4739 10.7483C25.4739 10.2618 25.075 9.87256 24.5884 9.87256H6.40165C5.91511 9.87256 5.52588 10.2618 5.52588 10.7483C5.52588 11.2251 5.91511 11.6338 6.40165 11.6338ZM6.40165 16.0127H24.5884C25.075 16.0127 25.4739 15.6137 25.4739 15.1369C25.4739 14.6406 25.075 14.2417 24.5884 14.2417H6.40165C5.91511 14.2417 5.52588 14.6406 5.52588 15.1369C5.52588 15.6137 5.91511 16.0127 6.40165 16.0127ZM6.40165 20.4012H24.5884C25.075 20.4012 25.4739 20.0023 25.4739 19.506C25.4739 19.0195 25.075 18.6302 24.5884 18.6302H6.40165C5.91511 18.6302 5.52588 19.0292 5.52588 19.506C5.52588 19.9925 5.91511 20.4012 6.40165 20.4012Z" fill="#F5F5F5"/>
          </svg>
        </div>
        <div class="back">
          <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.41187 25.588C10.0842 25.588 10.6072 25.0864 10.6072 24.4247V17.018C10.5752 16.8793 10.5538 16.7405 10.5538 16.5804C10.5538 16.431 10.5752 16.2923 10.6072 16.1535V8.75753C10.6072 8.09584 10.0842 7.59424 9.41187 7.59424C8.7395 7.59424 8.21655 8.09584 8.21655 8.75753V24.4247C8.21655 25.0864 8.7395 25.588 9.41187 25.588ZM24.0438 25.1824C24.9829 25.1824 25.7727 24.478 25.7727 23.176V10.0062C25.7727 8.70417 24.9829 7.99979 24.0438 7.99979C23.5742 7.99979 23.1686 8.13853 22.7097 8.40534L11.7705 14.8301C11.1301 15.2144 10.7459 15.6306 10.6072 16.1535V17.018C10.7459 17.5409 11.1301 17.9785 11.7705 18.3521L22.7097 24.7769C23.1686 25.0437 23.5742 25.1824 24.0438 25.1824ZM23.4141 22.7171C23.3394 22.7171 23.2647 22.6957 23.1793 22.6424L13.3713 16.8472C13.2539 16.7832 13.1792 16.7085 13.1792 16.5911C13.1792 16.463 13.2433 16.399 13.3713 16.335L23.1793 10.5398C23.2754 10.4865 23.3394 10.4651 23.4034 10.4651C23.5422 10.4651 23.6596 10.5505 23.6596 10.7426V22.4396C23.6596 22.6317 23.5422 22.7171 23.4141 22.7171Z" fill="#F5F5F5"/>
          </svg>
        </div>
        <div class="play" @click="play()">
          <svg v-show="!playmusic" width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.6543 29.0038C13.1553 29.0038 13.5966 28.8248 14.1453 28.5028L28.2323 20.356C29.2581 19.7596 29.6875 19.2944 29.6875 18.5429C29.6875 17.7914 29.2581 17.3382 28.2323 16.7298L14.1453 8.58301C13.5966 8.26095 13.1553 8.08203 12.6543 8.08203C11.6762 8.08203 10.9844 8.8335 10.9844 10.0144V27.0714C10.9844 28.2642 11.6762 29.0038 12.6543 29.0038ZM13.537 26.0456C13.4773 26.0456 13.4296 25.986 13.4296 25.9025V11.1833C13.4296 11.0879 13.4773 11.0402 13.537 11.0402C13.5847 11.0402 13.6324 11.064 13.6801 11.0998L26.3477 18.4117C26.3954 18.4475 26.4431 18.4833 26.4431 18.5429C26.4431 18.6025 26.3954 18.6383 26.3477 18.6741L13.6801 25.986C13.6324 26.0218 13.5847 26.0456 13.537 26.0456Z" fill="#F5F5F5"/>
          </svg>

          <svg v-show="playmusic" width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M14.8252 28.5979C15.5767 28.5979 16.1611 28.0373 16.1611 27.2978V9.78746C16.1611 9.04792 15.5767 8.4873 14.8252 8.4873C14.0737 8.4873 13.4893 9.04792 13.4893 9.78746V27.2978C13.4893 28.0373 14.0737 28.5979 14.8252 28.5979ZM23.1629 28.5979C23.9143 28.5979 24.4988 28.0373 24.4988 27.2978V9.78746C24.4988 9.04792 23.9143 8.4873 23.1629 8.4873C22.4114 8.4873 21.8269 9.04792 21.8269 9.78746V27.2978C21.8269 28.0373 22.4114 28.5979 23.1629 28.5979Z" fill="#F5F5F5"/>
          </svg>

        </div>
        <div class="next">
          <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M9.94549 25.1824C10.4151 25.1824 10.8206 25.0437 11.2795 24.7769L22.2188 18.352C22.8485 17.9785 23.2433 17.5409 23.3714 17.018V24.4247C23.3714 25.0864 23.8944 25.588 24.5667 25.588C25.2498 25.588 25.7727 25.0864 25.7727 24.4247V8.75753C25.7727 8.09584 25.2498 7.59424 24.5667 7.59424C23.8944 7.59424 23.3714 8.09584 23.3714 8.75753V16.1535C23.2433 15.6306 22.8591 15.2144 22.2188 14.8301L11.2795 8.40534C10.81 8.13853 10.4151 7.99979 9.93481 7.99979C8.99564 7.99979 8.21655 8.70417 8.21655 10.0062V23.176C8.21655 24.478 9.00631 25.1824 9.94549 25.1824ZM10.5752 22.7171C10.4364 22.7171 10.3297 22.6317 10.3297 22.4396V10.7426C10.3297 10.5505 10.4364 10.4651 10.5752 10.4651C10.6392 10.4651 10.7139 10.4865 10.81 10.5398L20.6072 16.335C20.7353 16.399 20.7994 16.463 20.7994 16.5911C20.7994 16.7085 20.7353 16.7832 20.6072 16.8472L10.81 22.6424C10.7139 22.6957 10.6392 22.7171 10.5752 22.7171Z" fill="#F5F5F5"/>
          </svg>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NombreDelComponente',  // Nombre del componente
  data() {
    return {
      title: '404 not found',
      timeLeft: 0, // tiempo inicial en segundos
      timer: null,  // variable para almacenar el temporizador
      campana:null,
      musica:null,
      playmusic:false,
      personalizarMenu:false,
      minutosP:0,
      segundosP:0
    };
  },
  methods: {
    startTimer(time) {
      this.personalizarMenu=false
      if (this.timer) {
        clearInterval(this.timer); // en caso de que ya esté corriendo, lo reinicia
      }
      this.timeLeft = time; // restablecer el tiempo inicial
      this.timer = setInterval(() => {
        if (this.timeLeft > 0) {
          this.timeLeft--;
        } else {
          this.playCampana()
          clearInterval(this.timer); // detener el temporizador cuando llegue a 0
        }
      }, 1000); // reducir 1 cada segundo (1000 ms)
    },
    iniciarTiempo(minutos,segundos) {
      console.log(minutos)
      console.log(segundos)
      const time=(minutos*60)+segundos
      if (time>0) {
        this.startTimer(time)  
      }
      

    },
    playCampana() {
      // Crea el objeto de audio si no existe
      if (!this.campana) {
        this.campana = new Audio(require('../../public/audio/campana.mp3')); // Ruta del archivo de sonido
        this.campana.addEventListener('ended',this.endCampana);
      }
      this.campana.play(); // Reproduce el sonido
    },
    endCampana(){
      this.campana.pause();
    },
    play(){
      if (this.playmusic) {
        this.endMusica()
      }
      else{
        this.playMusica()
      }
    },
    playMusica() {
      // Crea el objeto de audio si no existe
      if (!this.musica) {
        this.musica = new Audio(require('../../public/audio/musica.mp3')); // Ruta del archivo de sonido
        this.musica.addEventListener('ended',this.endMusica);
      }
      this.musica.play(); // Reproduce el sonido
      this.playmusic=true
    },
    endMusica(){
      if(this.musica){
        this.musica.pause();  
      }
      
      this.playmusic=false
    },
    personalizar(){
      this.personalizarMenu=!this.personalizarMenu
    }
  },
  beforeUnmount() {
    if (this.timer) {
      clearInterval(this.timer); // asegurar detener el temporizador cuando el componente se destruya
    }
    this.endMusica()
  },
  computed: {
    contador(){
      const minutos=Math.trunc(this.timeLeft/60)
      const segundos= this.timeLeft-(minutos*60)

      let minutosreturn =0
      if (minutos<10) {
        minutosreturn="0"+minutos
      }
      else{
        minutosreturn=minutos
      }

      let segundosreturn =0
      if (segundos<10) {
        segundosreturn="0"+segundos
      }
      else{
        segundosreturn=segundos
      }
      
      return minutosreturn+":"+segundosreturn;
    },
    personalizadoClass(){
      if (this.personalizarMenu) {
        return "pressButton"
      }
      else
      {
        return "a"
      }
    }
  },
  mounted() {
  },
  unmounted(){
    this.endMusica()
  }
};
</script>

<style scoped>
.imgOratorio{
  background-image: url("../../public/img/0c427aca104fa0acb07d735e3c6d885a.jpg");
  background-size: cover;
  height: calc(100vh);
  padding: 41px;
  padding-top: 107px;
  display: block;
  background-position-y: center;
}
.contButtons{
  width: 450px;
  display: flex;
  height: 32px;
}
.contButtons div{
  margin: auto;
}
.contButtonsButton{
  color: #f5f5f5;
background: #2468A8;
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
border-radius: 10px;
}
.pressButton{

/* Rectangle 21 */

box-sizing: border-box;

background: #2468A8;
box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25), inset 0px 4px 4px rgba(0, 0, 0, 0.25), inset 0px 4px 4px rgba(0, 0, 0, 0.25);
border-radius: 10px;

}
.espace{
  margin: auto;
}
h3{
  font-size: 36px;
}
.topcontainer{
  display: flex;
  width: 100%;
}
.contAudio{
  width: 250px;
  display: flex;
  position: fixed;
  bottom: 41px;
}
.contAudio div{
  margin: auto;
}
#menuPersonalizar{
  position: absolute;
  display: flex;
  align-items: end;
  width: 180px;
  margin-top: 10px;
  background-color: #2468A8;
  padding: 7px;
  border-radius: 10px;
}
#menuPersonalizar input{
  width: 50px;
  height: 25px;
  border-radius: 5px;
  padding: 2px;
  padding-left: 10px;
  margin: auto;
}
#menuPersonalizar div{
  display: block;
}
#menuPersonalizar .btn{
  position: absolute;
  top: 0px;
  margin-left: 180px;
}
@media (max-width: 768px) {
  .imgOratorio{
    background-position-x: center;
        display: flex;
    justify-content: center;
  }
  .topcontainer{
    display: flex;
    width: 100%;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 20px;
  }
  .contButtons{
    width: 100%;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }
  .contButtons div{
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 0;
  }
  h3{
    font-size: 100px;
    text-shadow: 5px 5px 5px black;
  }
  .contador{
    text-align: center;
  }
  .contButtons .btn{
    width: 100%;
  }
  #menuPersonalizar{
      position: relative;
      align-items: flex-start;
  }
  #menuPersonalizar .btn{
    position: relative;
    margin-left: auto;
  }
  .menu,.back,.next{
    display: none;
  }
  .play{
    width: 100px;
    height: 100px;
    background-color: #2468A8;
    border-radius: 50px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .contAudio{
    width: 100%;
  }
  .espace{
    margin: 0;
  }
}
</style>