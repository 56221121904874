<template>
  <div class="receta">
        <h2>Rosquillas Portugalujas</h2>
        <div class="imagenReceta9">
            
        </div>
        
        <h3>Ingredientes:</h3>
        <ul>
            <li>2 huevos</li>
            <li>100 g de azúcar</li>
            <li>1 cucharadita de canela</li>
            <li>60 g de aceite</li>
            <li>40 g de anisete</li>
            <li>400 g de harina</li>
            <li>1 sobre de levadura química</li>
        </ul>
        <h3>Elaboración:</h3>
        <p class="elaboracion">1. Batir los huevos con el azúcar y la canela en una batidora durante 3 minutos a 120°C.<br>
        2. Añadir el aceite, anisete, harina y la levadura. Amasar hasta obtener una masa suave.<br>
        3. Formar bolas del tamaño de una pelota de ping pong y hacer un agujero en el centro.<br>
        4. Calentar abundante aceite de girasol en una sartén profunda y freír las rosquillas hasta que estén doradas por ambos lados.<br>
        5. Escurrir sobre papel de cocina y rebozar en una mezcla de azúcar y canela mientras aún están calientes.</p>
    </div>
</template>

<script>
  export default {
    name: 'NombreDelComponente',  // Nombre del componente
    data() {
      return {
        title: '404 not found',
      };
    },
    methods: {
      // Aquí puedes definir métodos
    },
    computed: {
      // Computed properties
    },
    mounted() {
      // Código que se ejecuta cuando el componente se monta
    },
  };
</script>

<style scoped>
  
</style>