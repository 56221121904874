<template>
  <div class="vista">

    
    <div class="cont">
      <div class="left">
      <h4>Nombre</h4>
      <input type="text" v-model="datosEnvio.nombre" name="" class="form-control leftInput">
      <h4>Email</h4>
      <input type="text" v-model="datosEnvio.email" name="" class="form-control leftInput">
      <h4>Teléfono</h4>
      <input type="text" v-model="datosEnvio.telefono" name="" class="form-control leftInput">
      <h4>Mensajes</h4>
      <textarea name="" v-model="datosEnvio.mensaje" class="form-control leftInput inputMensaje">
      </textarea>
    </div>

    <div class="right">
      <div class="box boxProducto">
        <div class="top">
          <div class="contImg">
            <img :src="imgsURL[0]">
          </div>
          <div class="topCenter">
            <h4>{{nombre}}</h4>
            <h4>x1</h4>
          </div>
          <div class="topRight"><p>{{precio}}</p></div>
        </div>
        <hr>
        <div class="bottom">
          <div class="leftBottom">
            <h5>Método de pago</h5>
            <h5>Método de envío</h5>
            <h4>Subtotal</h4>
          </div>
          <div class="rightBottom">
            <h5>A convenir</h5>
            <h5>A convenir</h5>
            <h4>{{precio}}</h4>
          </div>
        </div>
      </div>

      <div class="box boxMetodos">
        <div>
          <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M3.78278 31.8014C3.78278 32.5058 4.25237 32.9892 4.97056 32.9892H39.0294C39.7476 32.9892 40.2172 32.5058 40.2172 31.8014V11.1258C40.2172 10.4076 39.7476 9.93799 39.0294 9.93799H4.97056C4.25237 9.93799 3.78278 10.4076 3.78278 11.1258V31.8014ZM6.53125 29.8402V13.087C6.53125 12.8108 6.65555 12.6865 6.93178 12.6865H37.082C37.3583 12.6865 37.4687 12.8108 37.4687 13.087V29.8402C37.4687 30.1164 37.3583 30.2269 37.082 30.2269H6.93178C6.65555 30.2269 6.53125 30.1164 6.53125 29.8402ZM7.88476 28.1552C7.88476 28.6248 8.14718 28.8872 8.60296 28.8872H35.4109C35.8666 28.8872 36.129 28.6248 36.129 28.1552V14.772C36.129 14.3024 35.8666 14.04 35.4109 14.04H8.60296C8.14718 14.04 7.88476 14.3024 7.88476 14.772V28.1552ZM9.34877 27.1332V15.794C9.34877 15.6007 9.44545 15.504 9.63881 15.504H18.7819C17.6218 16.7884 16.876 18.8463 16.876 21.4429C16.876 24.0532 17.6218 26.1249 18.7958 27.4232H9.63881C9.44545 27.4232 9.34877 27.3127 9.34877 27.1332ZM27.1378 21.4429C27.1378 18.8463 26.392 16.7884 25.1904 15.504H34.375C34.5545 15.504 34.665 15.6007 34.665 15.794V27.1332C34.665 27.3127 34.5545 27.4232 34.375 27.4232H25.1766C26.3782 26.1249 27.1378 24.0532 27.1378 21.4429ZM21.9448 27.147C20.1078 27.147 18.5472 24.951 18.5472 21.4429C18.5472 17.9624 20.1078 15.7802 21.9448 15.7802C23.8645 15.7802 25.4667 17.9624 25.4667 21.4429C25.4667 24.951 23.8645 27.147 21.9448 27.147Z" fill="#2468A8"/>
</svg>

          <h5>Nos comunicaremos para coordinar el Método de pago</h5>
        </div>
        <hr>
        <div>
          <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M10.4399 38.1134H12.0972C13.2988 38.1134 14.1137 37.2986 14.1137 36.1108V33.9286C16.7655 34.1219 19.4311 34.2186 22 34.2186C24.5689 34.2186 27.2345 34.1357 29.8863 33.9286V36.1108C29.8863 37.2986 30.7012 38.1134 31.9028 38.1134H33.5739C34.7755 38.1134 35.5904 37.2986 35.5904 36.1108V19.0537H36.2119C37.0958 19.0537 37.7312 18.4736 37.7312 17.645V11.8994C37.7312 11.0569 37.0958 10.4907 36.2119 10.4907H35.5904V9.05427C35.5904 7.12068 34.4855 5.94671 32.4552 5.68429C29.5686 5.3252 25.7705 5.13184 22 5.13184C18.2295 5.13184 14.459 5.31138 11.5448 5.68429C9.52832 5.96052 8.4096 7.12068 8.4096 9.05427V10.4907H7.78809C6.90416 10.4907 6.26883 11.0569 6.26883 11.8994V17.645C6.26883 18.4736 6.90416 19.0537 7.78809 19.0537H8.4096V36.1108C8.4096 37.2986 9.22447 38.1134 10.4399 38.1134ZM12.6911 24.5506C11.7796 24.3987 11.3238 23.8601 11.3238 22.8518V10.2559C11.3238 9.13714 11.8348 8.54325 12.8569 8.41895C18.2157 7.76981 25.7429 7.79743 31.1708 8.41895C32.1928 8.54325 32.69 9.13714 32.69 10.2559V22.8518C32.69 23.8739 32.2342 24.4125 31.3227 24.5506C25.881 25.3793 18.0085 25.4069 12.6911 24.5506ZM13.5889 30.821C12.553 30.821 11.7796 30.0476 11.7796 29.0255C11.7796 27.9897 12.553 27.2162 13.5889 27.2162C14.6109 27.2162 15.3843 27.9897 15.3843 29.0255C15.3843 30.0476 14.6109 30.821 13.5889 30.821ZM30.4249 30.821C29.3891 30.821 28.6157 30.0476 28.6157 29.0255C28.6157 27.9897 29.3891 27.2162 30.4249 27.2162C31.447 27.2162 32.2342 27.9897 32.2342 29.0255C32.2342 30.0476 31.447 30.821 30.4249 30.821ZM18.9891 30.2686C18.2433 30.2686 17.7185 29.7437 17.7185 28.9979C17.7185 28.2521 18.2433 27.7411 18.9891 27.7411H24.9833C25.7291 27.7411 26.2677 28.2521 26.2677 28.9979C26.2677 29.7437 25.7291 30.2686 24.9833 30.2686H18.9891Z" fill="#2468A8"/>
</svg>

          <h5>Nos comunicaremos para coordinar el Método de envío</h5>
        </div>
      </div>
    </div>
    </div>
    <button class="btn encargarButtons" @click="encargar()">Encargar</button>
  </div>
</template>

<script>
  import axios from 'axios';

export default {
  data() {
    return {
      nombre:"Vísperas Monasterio San Macario",
      precio:"$6.000",
      especificaciones:{
        titulo:"Vísperas Monasterio San Macario",
        autor:"Ottero, Ariel",
        editorial:"Lliu Lliu",
        formato:"PDF",
        paginas:"56"
      },
      imgsURL:["/img/tapa(8).jpg","/img/contratapa(8).jpg"],
      imgSelected:"",
      text:"descripcion",
      datosEnvio:{
        nombre:"",
        email:"",
        telefono:"",
        mensaje:"",
        producto:""
      },
      error:""
    };
  },
  methods: {
    setText(text){
      this.text=text
    },
    cambiarImagen(index){
      this.imgSelected=this.imgsURL[index]
    },
    async encargar() {
      try {
        const response = await axios.post('https://macariobackend-production.up.railway.app/api/products/encargar', {
          email: this.datosEnvio.email,
          nombre: this.datosEnvio.nombre,
          telefono: this.datosEnvio.telefono,
          mensaje: this.datosEnvio.mensaje,
          producto:this.especificaciones
        });
        // Manejar la respuesta
        this.error = response.data.message
        console.log(this.error);
        alert(this.error);
      } catch (err) {
        this.error = 'Hubo un error en el registro';
        console.error(err);
        alert(this.error);
      }
    }
  },
  mounted() {
    this.imgSelected=this.imgsURL[0]
  },
};
</script>

<style scoped>
.vista{
  height:100vh - 66px;
  width: 100%;
  padding: 41px;
  padding-top: 80px;
  color: black;
  display: block;
  background-color: #f5f5f5;
}
.encargarButtons{
  
  width: 389px;
  height: 54px;
  color: #f5f5f5;
  background: #2468A8;
  box-shadow: 0px 3.8px 3.8px rgba(0, 0, 0, 0.25);
  border-radius: 19px;
  font-size: 22px;

}
.left{
  margin-right: auto;
  display: grid;
  width: 600px;
  height: 100%;
}
.leftInput{
  box-sizing: border-box;
  height: 40px;

  background: #FFFFFF;
  border: 1.34237px solid #EAEAEA;
  border-radius: 6.71186px;
}
.inputMensaje{
  height: 140px;
}
.right{
  margin-left: auto;
  margin-right: auto;
  width: 495;
  height: 100%;
  display: grid;
}
.box{
  background: #FFFFFF;
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 40px;
}
.boxProducto{
  width: 493px;
  height: 287px;
}
.top{
  display: flex;
}
.contImg{
  height: 90px;
  width: 90px;
  overflow: hidden;
  border-radius: 8px;
  margin-right: 20px;
}
.contImg img{
  width: 100%;
}
.topCenter{
  width: 250px;
  margin-right: auto;
}
.topRight{
  display: flex;
}
.topRight p{
  font-size: 19px;
  margin: auto;
}
.bottom{
  margin-top: 0;
  height: 155px;
}
.bottom h5{
  font-size: 17px;
}
.bottom h4{
  font-size: 22px;
}
.leftBottom{
  width: 100%;
  display: grid;
}
.rightBottom{
  width: 92px;
  display: grid;
}
.boxMetodos{
  width: 493px;
  height: 144px;
  display: grid;
}
.boxMetodos div{
  display: flex;
}
.boxMetodos h5{
  font-size: 15px;
  margin: auto;
}
.boxMetodos hr{
  margin: 0;
}
h4{
  font-size: 20px;
}
.cont{
  display: flex;
  height: 100%;
}

@media (max-width: 768px) {
  .cont{
    display: flex;
    height: auto;
    flex-direction: column;
    gap: 20px;
    padding-top: 20px;
  }
  .left, .right{
    width: 100%;
  }
  .right{
    overflow: scroll;
  }
  .box{
    width: 100%;
  }
  .topCenter{
    width: auto;
  }
  .boxMetodos div{
    gap: 10px;
  }
  .topRight{
    display: none;
  }
  .encargarButtons{
    width: 100%;
  }
}
</style>