<template>
  <div class="vista">
    <div class="opacity">
      <div>
        <h3>Esta sección esta en construcción.</h3>
        <h3>Disculpe las molestias!</h3>
      </div>
    </div>
    <div class="contimg imginicio">
      
    </div>
  </div>
</template>

<script>
  export default {
    name: 'NombreDelComponente',  // Nombre del componente
    data() {
      return {
        title: '404 not found',
      };
    },
    methods: {
      // Aquí puedes definir métodos
    },
    computed: {
      // Computed properties
    },
    mounted() {
      // Código que se ejecuta cuando el componente se monta
    },
  };
</script>

<style scoped>
  
.imginicio{
  background-image: url("../../public/img/6635329a55d7e23765feccfcab5c4fae.jpg");
}
.opacity{
  position: absolute;
  display: flex;
}
.opacity div{
  margin: auto;
  text-align: center;
}

@media (max-width: 768px) {
  .contimg{
    background-position-x: 70%;
  }
  h3{
    font-size: 24px;
  }
  .opacity{
    padding: 10px;
    height: 100vh;
  }
}
</style>