<template>
  <div id="app">
    <div class="navbar">
      <div class="nav-links" :class="openClass">
        <div class="navbuttons">
        
          <div class="navItem">
            <span class="auto"><router-link @click="openClose()" to="/">Home</router-link></span>
          </div>
          
        
          <div class="navItem">
            <span class="auto"><router-link @click="openClose()" to="/oratorio">Oratorio</router-link></span>
          </div>
          
        
          <div class="navItem">
            <span class="auto"><router-link @click="openClose()" to="/biblioteca">Biblioteca</router-link></span>
          </div>
          
        
          <div class="navItem">
            <span class="auto"><router-link @click="openClose()" to="/cocina">Cocina</router-link></span>
          </div>
          
        
          <div class="navItem">
            <span class="auto"><router-link @click="openClose()" to="/retiro">Retiros</router-link></span>
          </div>
          
        
          <div class="navItem">
            <span class="auto"><router-link @click="openClose()" to="/mantenimiento">Cursos</router-link></span>
          </div>
          
        
          <div class="navItem">
            <span class="auto"><router-link @click="openClose()" to="/mantenimiento">Santería</router-link></span>
          </div>
          
      </div>
      <div class="logbuttons">
        <div>
          <router-link to="/login" @click="openClose()"><button class="btn" >Iniciar Sesión</button></router-link>
        </div>
        <div>
          <router-link to="/register" @click="openClose()"><button class="btn btnreg">Registrarse</button></router-link>
        </div>
      </div>
      </div>
      

      <div class="hamburger" @click="openClose()">
            <span></span>
            <span></span>
            <span></span>
        </div>
    </div>
    <router-view />
  </div>
</template>


<script>
export default {
  name: 'App',
  data() {
    return {
      email: '',
      password: '',
      error: '',
      nombre:'',
      open:true
    };
  },
  methods: {
    openClose(){
      this.open=!this.open
    }
  },
  computed:{
      openClass(){
          if (this.open===false) {
            return "open"
          }
          else{
            return ""
          }
      }
  }
};
</script>

<style>
/* Tus estilos aquí */
a{
    color: #f5f5f5;
  }
</style>
