<template>
  <div class="imglog">
    <div class="opacity">
      
    </div>
  </div>
  <form @submit.prevent="register">
    <div class="contlog">
      <h2>¡Bienvenido a San Macario!</h2>
      <div>
        <label for="email">Email:</label>
        <input type="email" v-model="email" required />
      </div>
      <div>
        <label for="password">Contraseña:</label>
        <input type="password" v-model="password" required />
      </div>
      <div>
        <label for="password2">Repetir Contraseña:</label>
        <input type="password" v-model="password2" required />
      </div>
      <button type="submit" class="btn" id="btnRegistrar">Registrarse</button>
    
    <p v-if="error">{{ error }}</p>
    </div>
</form>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      email: '',
      password: '',
      password2:'',
      error: '',
      nombre:''
    };
  },
  methods: {
    async register() {
      try {
        const response = await axios.post('https://macariobackend-production.up.railway.app/api/users/register', {
          email: this.email,
          password: this.password,
          nombre: this.nombre
        });
        // Manejar la respuesta
        this.error = response.data.message
        console.log('Registro exitoso:', this.error);
      } catch (err) {
        this.error = 'Hubo un error en el registro';
        console.error(err);
      }
    }
  }
};
</script>

<style scoped>
.imglog{
  overflow: hidden;
  display: flex;
  background-size: cover;
  background-image: url("../../public/img/a59e13482bebf3d94d1fb7b58785f3c6.jpg");
  height: 100vh;
  width: calc(100vw - 543px + 29px);
}
.contlog{
  padding: 68px;
  color: #000000;
  display: grid;
}
#btnRegistrar{
  width: 201px;
  height: 40px;
  background: #2468A8;
  border-radius: 25px;
  font-family: 'SF Pro Text';
  font-style: normal;
  font-weight: 300;
  font-size: 17px;
  line-height: 20px;
  color: #F5F5F5;
}
h2{
  font-size: 32px;
}

@media (max-width: 768px) {
  .contlog{
  padding: 20px;
    color: #000000;
    display: flex;
    gap: 20px;
    flex-direction: column;
    width: 100%;
    height: calc(70vh - 66px);
    margin-top: -20px;
    z-index: 9;
    position: sticky;
    border-radius: 20px 20px 0px 0px;
    
  }
  .contlog input{
    width: 100%;
  }
  .imglog{
    height: calc(30vh + 20px + 66px);
    width: 100%;
    background-position-x: center;
  }

}
</style>