<template>
  <div class="vista">
    <div class="topCocina">
      <div class="contLeft">
        <svg @click="frase=!frase" width="13" height="22" viewBox="0 0 13 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 10.5801C0 10.9976 0.155064 11.3555 0.489049 11.6656L9.76904 20.7547C10.0315 21.0172 10.3654 21.1603 10.7591 21.1603C11.5463 21.1603 12.1785 20.54 12.1785 19.7409C12.1785 19.3472 12.0115 19.0013 11.7491 18.727L3.38756 10.5801L11.7491 2.43331C12.0115 2.15897 12.1785 1.80113 12.1785 1.41943C12.1785 0.620257 11.5463 0 10.7591 0C10.3654 0 10.0315 0.143136 9.76904 0.405552L0.489049 9.48277C0.155064 9.80483 0 10.1627 0 10.5801Z" fill="#1C1C1E"/>
        </svg>
      </div>
      <div class="contimg" @click="frase=!frase">
        <div v-show="!frase" class="img1">
          <div class="opacity">
            <h2>Bienvenidos a nuestra cocina!</h2>
          </div>
          
        </div>
        <div v-show="frase" class="img2">
          <div class="opacity">
            <h4>Descubre la simplicidad y el sabor de la tradición monástica. Nuestras recetas, inspiradas en la sabiduría ancestral de los monjes, están hechas con ingredientes naturales y seleccionados para nutrir tanto el cuerpo como el espíritu. Disfruta de una cocina que celebra la paz, la conexión con la tierra y una alimentación equilibrada.</h4>  
          </div>
        </div>
        
      </div>
      <div class="contRight">
        <svg @click="frase=!frase" width="13" height="23" viewBox="0 0 13 23" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12.6697 11.5308C12.6578 11.1134 12.5027 10.7555 12.1807 10.4335L2.90067 1.35624C2.62633 1.09382 2.30427 0.950684 1.91064 0.950684C1.11147 0.950684 0.491211 1.57094 0.491211 2.37012C0.491211 2.75181 0.646275 3.10965 0.920619 3.384L9.27023 11.5308L0.920619 19.6777C0.646275 19.952 0.491211 20.2979 0.491211 20.6915C0.491211 21.4907 1.11147 22.111 1.91064 22.111C2.29234 22.111 2.62633 21.9678 2.90067 21.7054L12.1807 12.6163C12.5146 12.3062 12.6697 11.9483 12.6697 11.5308Z" fill="#1C1C1E"/>
        </svg>

      </div>
    </div>

    <div class="categoriasContainer">
      <div class="titleContainer">
        <h4>¿Qué te gustaría cocinar?</h4>
      </div>
      <div class="bodyContainer">
        <div class="categoria">
          <img src="../../public/img/ensalada.png">
          <h5>Platos Salados</h5>
        </div>
        <div class="categoria">
          <img src="../../public/img/dulce.png">
          <h5>Platos Dulces</h5>
        </div>
      </div>
        
    </div>

    <div class="recetasContainer">
      <div class="left" @click="scrollLeft">
        <svg width="13" height="22" viewBox="0 0 13 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 10.5801C0 10.9976 0.155064 11.3555 0.489049 11.6656L9.76904 20.7547C10.0315 21.0172 10.3654 21.1603 10.7591 21.1603C11.5463 21.1603 12.1785 20.54 12.1785 19.7409C12.1785 19.3472 12.0115 19.0013 11.7491 18.727L3.38756 10.5801L11.7491 2.43331C12.0115 2.15897 12.1785 1.80113 12.1785 1.41943C12.1785 0.620257 11.5463 0 10.7591 0C10.3654 0 10.0315 0.143136 9.76904 0.405552L0.489049 9.48277C0.155064 9.80483 0 10.1627 0 10.5801Z" fill="#1C1C1E"/>
        </svg>
      </div>
      <div class="center">
        <h3>Recetas populares</h3>
        <div ref="scrollContainer" class="recetasContainerContainer">
            <router-link v-for="(receta,index) in recetas" :key="index" class="boxReceta" :to="'/receta-'+(index+1)">
              <div class="imgReceta">
                <div class="containerImgReceta">
                  <img :src="receta.urlImagen">  
                </div>
                
                <h4>{{receta.nombre}}</h4>
                <div class="puntuacion"> 
                  <img src="../../public/img/star.png">
                  <img src="../../public/img/star.png">
                  <img src="../../public/img/star.png">
                  <img src="../../public/img/star.png">
                  <img src="../../public/img/star.png">
                </div>
              </div>
            </router-link>
        </div>
      </div>
      <div @click="scrollRight" class="right">
        <svg width="13" height="23" viewBox="0 0 13 23" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12.6697 11.5308C12.6578 11.1134 12.5027 10.7555 12.1807 10.4335L2.90067 1.35624C2.62633 1.09382 2.30427 0.950684 1.91064 0.950684C1.11147 0.950684 0.491211 1.57094 0.491211 2.37012C0.491211 2.75181 0.646275 3.10965 0.920619 3.384L9.27023 11.5308L0.920619 19.6777C0.646275 19.952 0.491211 20.2979 0.491211 20.6915C0.491211 21.4907 1.11147 22.111 1.91064 22.111C2.29234 22.111 2.62633 21.9678 2.90067 21.7054L12.1807 12.6163C12.5146 12.3062 12.6697 11.9483 12.6697 11.5308Z" fill="#1C1C1E"/>
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'NombreDelComponente',  // Nombre del componente
    data() {
      return {
        frase:false,
        title: '404 not found',
        recetas:[
          {
            nombre:"Tocino del cielo",
            urlImagen:"/img/receta(1).jpg",
            puntuacion:10
          },
          {
            nombre:"Suspiros de monja",
            urlImagen:"/img/receta(2).jpg",
            puntuacion:10
          },
          {
            nombre:"Hojaldrinas Santa María",
            urlImagen:"/img/receta(3).jpg",
            puntuacion:10
          },
          {
            nombre:"Panecillos de Santa Clara",
            urlImagen:"/img/receta(4).jpg",
            puntuacion:10
          },
          {
            nombre:"Rosquetas de manteca",
            urlImagen:"/img/receta(5).jpg",
            puntuacion:10
          },
          {
            nombre:"Tortas de polvorón",
            urlImagen:"/img/receta(6).jpg",
            puntuacion:10
          },
          {
            nombre:"Pan del Convento",
            urlImagen:"/img/receta(7).jpg",
            puntuacion:10
          },
          {
            nombre:"Pestiños",
            urlImagen:"/img/receta(8).jpg",
            puntuacion:10
          },
          {
            nombre:"Rosquillas Portugalujas",
            urlImagen:"/img/receta(9).jpg",
            puntuacion:10
          }
        ]
      };
    },
    methods: {
      scrollLeft() {
      // Hace scroll 300px a la izquierda del contenedor
      this.$refs.scrollContainer.scrollBy({ left: -281, behavior: 'smooth' });
    },
    scrollRight() {
      // Hace scroll 300px a la derecha del contenedor
      this.$refs.scrollContainer.scrollBy({ left: 281, behavior: 'smooth' });
    },
    },
    computed: {
      // Computed properties
    },
    mounted() {
      // Código que se ejecuta cuando el componente se monta
    },
  };
</script>

<style scoped>
.contLeft,.contRight{
  width: 60px;
  text-align: center;
  display: flex;
}
.contLeft svg,.contRight svg{
  margin: auto;
}
.topCocina{
      display: flex;
    width: 100%;
    margin-top: 66px;
}
.contimg{
  width: 1250px;
  height: 286px;
  
}
.categoriasContainer img{
  height: 132px;
}
.categoria{
  margin: auto;
  margin-top: 19px;
  text-align: center;
}
.categoriasContainer{
  color: black;
  padding: 41px;
  padding-top: 29px;
  width: 500px;
}
.titleContainer{
  width: 100%;
}
.bodyContainer{
  display: flex;
  height: 45vh;
  height: 100%;
}
h4{
  font-size: 18px;
}
.recetasContainer{
  color: black;
  display: flex;
  margin-bottom: 41px;
}
.recetasContainer .left,.recetasContainer .right{
  width: 144px;
  display: flex;
  text-align: center;
  align-items: center;
}
.recetasContainer .left svg,.recetasContainer .right svg{
  margin: auto;
}
.recetasContainer .center{
  overflow: scroll;
}
.recetasContainerContainer{
  overflow: scroll;
  width: 100%;
}
.imgReceta .containerImgReceta img{
  width: 220px;
  min-height: 143px;
}
.boxReceta{
width: 240px;
height: 220px;
padding: 10px;
text-align: center;
background: #D3E3FD;
border-radius: 15px;
margin-right: 41px;
display: block;
color: black;
}
.puntuacion{
  display: flex;
  width: 134px;
  margin: auto;
}
.puntuacion img{
  width: 19px;
  height: 18px;
  margin: auto;
}
.recetasContainerContainer{
  display: flex;
}
::-webkit-scrollbar {
  display: none;
}
.img1{
  width: 100%;
  background-image: url(../../public/img/11534a03905e53f61a939a1c899ff032.jpg);
      background-size: cover;
    background-position-y: center;
}
.img2{
  width: 100%;
  background-image: url(../../public/img/03d97a4acd5852164380e23d42b16c87.jpg);
      background-size: cover;
    background-position-y: center;
    text-align: center;
}
.img1 h2{
  margin: auto;

}
.img2 h4{
  margin: auto;
  width: 669px;
}
.opacity{
  display: flex;
}
.containerImgReceta{
  width: 220px;
  height: 143px;
  border-radius: 15px;
  overflow: hidden;
}

@media (max-width: 768px) {
  .contLeft,.contRight,.categoriasContainer,.recetasContainer .right,.recetasContainer .left{
    display: none;
  }
  .recetasContainerContainer{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
  }
  .center{
    text-align: center;
  }
  .boxReceta{
    margin: 0;
    width: 45%;
  }
  .containerImgReceta{
    width: 100%;
  }
  .puntuacion{
    display: none;
  }
  .imgReceta{
    
    display: grid;
    align-items: center;
    height: 100%;
    align-content: space-between;
  }
  .opacity{
    padding: 10px;
  }
  .vista{
        gap: 20px;
    display: flex;
    flex-direction: column;
  }
}
</style>