<template>
  <div class="receta">
        <h2>Pan del convento</h2>
        <div class="imagenReceta7">
            
        </div>
        
        <h3>Ingredientes:</h3>
        <ul>
            <li>1/4 taza de agua tibia</li>
            <li>1 taza de leche</li>
            <li>1 cda. de levadura</li>
            <li>2 cdas. de azúcar</li>
            <li>1 cta. de sal</li>
            <li>1 huevo batido</li>
            <li>60 g de mantequilla</li>
            <li>3 tazas de harina</li>
        </ul>
        <h3>Elaboración:</h3>
        <p class="elaboracion">1. Disolver la levadura en el agua tibia y dejar reposar hasta que espume.<br>
        2. Calentar la leche ligeramente y añadir la mantequilla, sal y azúcar. Dejar que la mantequilla se derrita.<br>
        3. Mezclar la levadura con la mezcla de leche y añadir el huevo batido.<br>
        4. Incorporar la harina poco a poco y batir hasta que la masa esté suave y elástica.<br>
        5. Cubrir la masa con un paño y dejar reposar por 2 horas hasta que duplique su volumen.<br>
        6. Formar bolitas con la masa y colocarlas en moldes engrasados. Dejar que suban de nuevo durante media hora.<br>
        7. Barnizar con huevo batido y hornear a 350°F (180°C) por 20 minutos o hasta que doren.</p>
    </div>
</template>

<script>
  export default {
    name: 'NombreDelComponente',  // Nombre del componente
    data() {
      return {
        title: '404 not found',
      };
    },
    methods: {
      // Aquí puedes definir métodos
    },
    computed: {
      // Computed properties
    },
    mounted() {
      // Código que se ejecuta cuando el componente se monta
    },
  };
</script>

<style scoped>
  
</style>